@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  html,
  body {
    @apply w-screen h-screen overflow-y-scroll;
  }

  body {
    @apply font-inter m-0 p-0 text-xs leading-4;
  }

  a {
    @apply decoration-0 text-blue-500;
  }

  .btn {
    @apply cursor-pointer text-sm rounded-lg px-4 py-2.5 transition-colors;

    &_primary {
      @apply text-white bg-purple-500 hover:bg-purple-600;
      @apply disabled:hover:bg-slate-100 disabled:hover:text-slate-200 disabled:bg-slate-100 disabled:text-slate-200 focus:bg-opacity-50;
    }

    &_outline {
      @apply text-slate-500 rounded-lg border border-slate-100 hover:bg-slate-50 hover:border-slate-200 disabled:border-none;
      @apply disabled:hover:bg-slate-100 disabled:hover:text-slate-200 disabled:bg-slate-100 disabled:text-slate-200 focus:bg-opacity-50;

      &-pink {
        @apply text-purple-500 border-purple-100 hover:text-purple-600 hover:bg-purple-100 hover:border-purple-200;
      }
    }
  }

  .form-control {
    @apply w-full text-sm placeholder-slate-300 border bg-slate rounded-lg border-slate-100 px-3 py-2 outline-none;

    &:not(:read-only) {
      @apply hover:bg-slate-50 hover:border-slate-200 focus:border-effect-purple;
    }

    &:focus:not(:read-only) {
      box-shadow: 0 0 0 1px rgba(98, 1, 255, 0.5);
    }

    &.error {
      @apply text-red-600 border-red-600;
    }
  }

  textarea {
    @apply min-h-[82px] py-2 px-3 resize-none;
  }

  label {
    @apply text-sm text-slate-900 font-semibold block mb-1;

    &.required::after {
      content: '*';
      @apply text-red-600 pl-1;
    }
  }
}

::-webkit-scrollbar {
  max-width: 6px;
  max-height: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(118, 125, 137, 0.24);
  @apply rounded-lg;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-slate-200;
}
